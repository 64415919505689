@font-face {
  font-family: alt;
  src: url(../../ALT_REKT_SOLID.ttf);
}
.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 3vh;
  position: relative;
  top: 35vh;
  margin-bottom: 75vh;
  font-family: alt;
  font-size: 7vh;
  color: #fff;
}
.countdown-wrapper-btn {
  text-align: center;
  padding: 3vh;
  color: white;
  font-family: alt;
  font-size: 6vh;
  background: rgba(0, 0, 0, 0.4);
  border: 0.5vh solid white;
  cursor: pointer;
  margin-top: 3vh;
}
.countdown-item {
  color: #fff;
  font-weight: lighter !important;
  display: flex;
  font-family: alt !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 15vh;
  margin: 10px;

  position: relative;
}

.countdown-item span {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer p {
  margin: 10px 0;
}

footer i {
  color: red;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
}
h3 {
  font-size: 8vh;
  padding: 3vh;
  position: relative;
  top: 6vh;
}
h6 {
  font-size: 2.7vh;
  position: relative;
  bottom: 7.6vh;
}
.vl {
  border-left: 1px solid rgb(255, 255, 255);
  height: 12vh;
}
@media screen and (max-width: 800px) {
  h3 {
    font-size: 4vh;
    position: relative;
    top: 6vh;
  }
  h6 {
    font-size: 2.5vh;
    position: relative;
    bottom: 5vh;
  }
  .countdown-wrapper {
    font-size: 4vh;
  }
  .countdown-wrapper-btn {
    font-size: 4vh;
  }
}

@media (max-width: 400px) {
  .countdown-wrapper {
    padding: 0;
  }
  .countdown-item {
    margin: 5px;
  }
  .countdown-wrapper {
    font-size: 4vh;
  }
  .countdown-wrapper-btn {
    font-size: 4vh;
  }
}
