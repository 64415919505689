.section1{
    background-image: url(../assets/Banner\ 3\ 1.png);
    background-repeat: no-repeat;
    background-size: 100% 100vh;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
      

   
}
@media screen and (max-width:800px) {
    .section1{
         background-size: cover;
        
    }
    
    
}
