@font-face {
  font-family: alt;
  src: url(../ALT_REKT_SOLID.ttf);
}
/* .navbar{
    border:1px solid red;
    color: white;
    background-color: grey;
    position: fixed;
} */

.pages {
  color: #316685;
  text-align: center;
  font-size: calc(1.5rem + 2vw);
  margin-top: 10%;
}

.navbar {
  background-color: transparent;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: absolute !important;
  top: 0;
  z-index: 20;
  font-family: roboto-regular;
  font-size: large;
  font-weight: lighter !important;
  /* box-shadow: rgba(233, 233, 233, 0.25) 0px 50px 100px -20px,
    rgba(145, 145, 145, 0.3) 0px 30px 60px -30px,
    rgba(155, 167, 179, 0.35) 0px -2px 6px 0px inset; */
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
}

.main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.nav-logo {
  position: relative;
  left: 10vw;
  top: 2vh;
}
.bar__logo {
  width: 20vw;
  height: auto;
  position: relative;
  top: 1vh;
}
.nav-menu {
  display: flex;
  list-style: none;
  align-items: center;
  position: absolute;
  right: 10vw;
  top: 1.7vh;
}

.nav-links {
  color: white;
  text-decoration: none;

  height: 100%;
  border-bottom: 3px solid transparent;

  letter-spacing: 2px !important;
}
.nav-links__left {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  letter-spacing: 3px !important;
  height: 100%;
  border-bottom: 3px solid transparent;
  font-weight: bolder;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  font-size: 4vh;
  margin-right: 2vw;
  font-family: alt !important;
  position: relative;
  top: 1vh;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item .active {
  color: #ffdd40;
  border: 1px solid #fc0071;
}

.nav-icon {
  display: none;
}
.btn__appbar {
  color: #fff;
  background-color: #fc0071 !important;
  width: 90%;
  vertical-align: text-top !important;
  transform: skewX(-15deg);
  border: 1.3px solid white !important;
  font-family: roboto-regular;
  font-size: large;
  font-weight: lighter !important;
  cursor: pointer;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  margin-bottom: 1rem;
  letter-spacing: 1px;
}
.btn-text {
  transform: skewX(15deg) !important;
}
.nav__width {
  width: 200px;
}
.nav-links__right {
  font-size: 3vh !important;
  display: none;
  letter-spacing: 3px !important;
}
@media screen and (max-width: 800px) {
  .bar__logo {
    width: 40vw;
    height: auto;
  }
  .nav-menu {
    display: none;
  }
  .nav-links__left {
    display: none;
  }
  .btn__appbar {
    color: #fff;
    position: relative;
    background-color: #fc0071 !important;
    transform: skew(-15deg);
    border: 1.3px solid white !important;
    right: 1.6rem !important;
  }
  .btn-text {
    transform: skewX(15deg) !important;
  }
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    width: 70%;
    position: relative;
    top: 38vh;
    left: 2vw;
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
    transition: all 0.5s ease;
    text-align: left;
  }
  .nav-item .active {
    color: #fc0071;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-links__right {
    padding: 1.5rem;

    display: table;
    color: white;
    text-decoration: none;
    padding: 1.5rem;

    border-bottom: 3px solid transparent;
  }
  .nav-icon {
    display: block;
    position: absolute;
    top: 4vh;
    right: 0rem;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fc0071;
  }
  .nav-icon2 {
    display: block;
    position: absolute;
    top: 4vh;
    right: 0rem;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 100;
  }
  .nav__width {
    width: 100%;
  }
}

@media screen and (max-width: 912px) {
  .bar__logo {
    width: 40vw;
    height: auto;
  }
  .nav-menu {
    display: none;
  }
  .nav-links__left {
    display: none;
  }
  .btn__appbar {
    color: #fff;
    position: relative;
    background-color: #fc0071 !important;
    transform: skew(-15deg);
    border: 1.3px solid white !important;
    right: 1.6rem !important;
  }
  .btn-text {
    transform: skewX(15deg) !important;
  }
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    width: 70%;
    position: relative;
    top: 38vh;
    left: 2vw;
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
    transition: all 0.5s ease;
    text-align: left;
  }
  .nav-item .active {
    color: #fc0071;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-links__right {
    padding: 1.5rem;

    display: table;
    color: white;
    text-decoration: none;
    padding: 1.5rem;

    border-bottom: 3px solid transparent;
  }
  .nav-icon {
    display: block;
    position: absolute;
    top: 4vh;
    right: 0rem;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fc0071;
  }
  .nav-icon2 {
    display: block;
    position: absolute;
    top: 4vh;
    right: 0rem;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 100;
  }
  .nav__width {
    width: 100%;
  }
}
/* --------------------------------------------------------------------------------------- */
@media screen and (width: 1024px) {
  .nav-item {
    font-size: 2vh !important;
  }
  .bar__logo {
    width: 20vw;
    height: auto;
  }
  .nav-menu {
    position: relative;
    top: 2vh;
    left: 10vw;
  }
}
@media screen and (width: 768px) {
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    width: 70%;
    position: relative;
    top: 25vh;
    left: 15vw;
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
    transition: all 0.5s ease;
    text-align: left;
  }
}
@media screen and (width: 736px) {
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    width: 70%;
    position: relative;
    top: 38vh;
    left: 14vw;
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
    transition: all 0.5s ease;
    text-align: left;
  }
}
@media screen and (width: 720px) {
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    width: 70%;
    position: relative;
    top: 38vh;
    left: 18vw;
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
    transition: all 0.5s ease;
    text-align: left;
  }
}
@media screen and (width: 280px) {
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    width: 70%;
    position: relative;
    top: 38vh;
    left: -3vw;
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
    transition: all 0.5s ease;
    text-align: left;
  }
}
